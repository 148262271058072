import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from '@remix-run/react';
import copy from 'copy-to-clipboard';
import { useCallback, useMemo, useState } from 'react';
import Select, { type SingleValue } from 'react-select';

import {
  type DtoProduct,
  type DtoProductDefaultsResponse,
  type ModelsPrice,
} from '@lp-lib/api-service-client/public';

import { type Option } from '../components/common/Utilities';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../components/ConfirmCancelModalContext';
import { ArchiveIcon } from '../components/icons/ArchiveIcon';
import { CopyIcon } from '../components/icons/CopyIcon';
import { DeleteIcon } from '../components/icons/DeleteIcon';
import { DuplicateIcon } from '../components/icons/DuplicateIcon';
import { PlusIcon } from '../components/icons/PlusIcon';
import { ShareIcon } from '../components/icons/ShareIcon';
import { Loading } from '../components/Loading';
import {
  ALL_REGISTER_FOR,
  type RegisterFor,
} from '../components/Onboarding/types';
import { MarketedProductsEditor } from '../components/Product/MarketedProductsEditor';
import {
  ProductActionSheetMenu,
  ProductActionSheetMenuItem,
  ProductsTable,
} from '../components/Product/ProductsTable';
import {
  useProductDefaults,
  usePublishedProducts,
  useUpdateProductDefaults,
} from '../components/Product/useProducts';
import { ProductUtils } from '../components/Product/utils';
import { TagPicker } from '../components/Tagging';
import { useInstance } from '../hooks/useInstance';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { type Tag } from '../types';
import { err2s } from '../utils/common';
import { buildReactSelectStyles } from '../utils/react-select';
import { tokenWithRedirect } from '../utils/router';

export const loader = async (action: ClientLoaderFunctionArgs) => {
  const resp = await tokenWithRedirect(
    () => apiService.product.getProducts(),
    action.request.url,
    { admin: true }
  );
  return resp.data;
};

function PricePicker(props: {
  prices: ModelsPrice[];
  selected: ModelsPrice | null;
  onSelect: (price: ModelsPrice | null) => void;
}): JSX.Element | null {
  const { prices, selected, onSelect } = props;
  const styles = useInstance(() => buildReactSelectStyles<ModelsPrice>());
  const options = useMemo(() => {
    return prices.filter((p) => !p.archived);
  }, [prices]);

  return (
    <Select<ModelsPrice, false>
      placeholder='Select price...'
      styles={styles}
      classNamePrefix='select-box-v2'
      className='w-full'
      value={selected}
      options={options}
      getOptionLabel={(price) =>
        `Up to ${price.maxSeats} seats – $${
          price.amount
        }/${ProductUtils.FormatInterval(price.billingInterval)}`
      }
      onChange={onSelect}
      isSearchable
      isClearable
    />
  );
}

function registerForToOption(registerFor: RegisterFor): Option<RegisterFor> {
  return {
    label: registerFor,
    value: registerFor,
  };
}

function RegisterForPicker(props: {
  value: RegisterFor;
  onChange: (value: RegisterFor) => void;
}) {
  const { value, onChange } = props;

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<RegisterFor>>()
  );

  return (
    <Select<Option<RegisterFor>, false>
      placeholder='Select Register For...'
      styles={styles}
      className='w-full'
      classNamePrefix={'select-box-v2'}
      value={registerForToOption(value)}
      options={ALL_REGISTER_FOR.map(registerForToOption)}
      onChange={(o) => o && onChange(o.value)}
    />
  );
}

function CopyRegisterLinkModal(props: {
  productId: string;
  prices: ModelsPrice[];
  onCopy: (url: string) => void;
  onCancel: () => void;
}) {
  const { productId, prices, onCopy, onCancel } = props;
  const [registerFor, setRegisterFor] = useState<RegisterFor>('coldOutreach');
  const [price, setPrice] = useState<ModelsPrice | null>(null);
  const [trial, setTrial] = useState(false);
  const [tag, setTag] = useState<Tag | null>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const url = useMemo(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('register-for', registerFor);
    searchParams.set('product-id', productId);
    if (price) {
      searchParams.set('price-id', price.id);
    }
    if (trial) {
      searchParams.set('trial', 'true');
    }
    if (tag) {
      searchParams.set('tag-id', tag.id.toString());
    }
    if (firstName) {
      searchParams.set('first-name', firstName);
    }
    if (lastName) {
      searchParams.set('last-name', lastName);
    }
    return `${window.location.origin}/register?${searchParams.toString()}`;
  }, [firstName, lastName, price, productId, registerFor, tag, trial]);

  const handleCopy = useCallback(() => {
    onCopy(url);
  }, [onCopy, url]);

  return (
    <div className='fixed inset-0 flex items-center justify-center'>
      <div
        className='absolute inset-0 bg-black bg-opacity-40'
        onClick={onCancel}
      />

      <div className='relative border border-secondary rounded-xl p-5 bg-modal text-white w-100 flex flex-col gap-2'>
        <RegisterForPicker value={registerFor} onChange={setRegisterFor} />

        <div className='flex items-center gap-2'>
          <PricePicker prices={prices} selected={price} onSelect={setPrice} />

          <label className='flex items-center'>
            <input
              type='checkbox'
              className='checkbox-dark'
              checked={trial}
              onChange={() => setTrial((prev) => !prev)}
            />
            <span className='ml-2'>Trial</span>
          </label>
        </div>

        {registerFor === 'subscription' && (
          <TagPicker
            placeholder='Search for an existing Category'
            multi={false}
            tags={tag ? [tag] : undefined}
            onChange={(t) => setTag(t)}
          />
        )}

        <div className='flex items-center gap-2'>
          <input
            type='string'
            className='field h-10'
            value={firstName}
            onChange={(e) => setFirstName(e.currentTarget.value)}
            placeholder='First Name'
          ></input>
          <input
            type='string'
            className='field h-10'
            value={lastName}
            onChange={(e) => setLastName(e.currentTarget.value)}
            placeholder='Last Name'
          ></input>
        </div>

        <div className='mt-2'>
          <p className='text-xs text-icon-gray break-words'>{url}</p>
        </div>

        <div className='flex justify-end mt-2'>
          <button
            type='button'
            className='btn btn-primary w-30 h-10'
            onClick={handleCopy}
          >
            Copy Link
          </button>
        </div>
      </div>
    </div>
  );
}

function AdminProductsLibrary() {
  const { marketed, published, drafts, archived } =
    useLoaderData<typeof loader>();
  const navigate = useNavigate();
  const [creating, setCreating] = useState(false);
  const [loading, setLoading] = useState(false);
  const revalidator = useRevalidator();
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleCreateProduct = async () => {
    setCreating(true);
    try {
      const resp = await apiService.product.createProduct({ name: 'Untitled' });
      const product = resp.data.product;
      navigate(`/admin/products/${product.id}`);
    } finally {
      setCreating(false);
    }
  };

  const handleEditProductDefaults = async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => {
        return (
          <EditProductSettings
            onCancel={p.internalOnCancel}
            onComplete={p.internalOnConfirm}
          />
        );
      },
    });
    revalidator.revalidate();
  };

  const handleArchive = async (productId: string) => {
    const response = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>Archive Product</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            Are you sure you want to archive this product? The product will no
            longer be active for purchase, however organizations may retain
            access to this product if they have already purchased it.
          </ConfirmCancelModalText>
        </div>
      ),
      confirmBtnLabel: 'Archive',
      confirmBtnVariant: 'delete',
    });
    if (response.result !== 'confirmed') return;

    await apiService.product.deleteProduct(productId);
    revalidator.revalidate();
  };

  const handleDelete = async (productId: string) => {
    const response = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>Delete Draft</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            Are you sure you want to delete this draft? This action cannot be
            undone.
          </ConfirmCancelModalText>
        </div>
      ),
      confirmBtnLabel: 'Delete',
      confirmBtnVariant: 'delete',
    });
    if (response.result !== 'confirmed') return;

    await apiService.product.deleteProduct(productId);
    revalidator.revalidate();
  };

  const handleDuplicate = async (productId: string) => {
    await apiService.product.duplicateProduct(productId);
    revalidator.revalidate();
  };

  const handlePublish = async (productId: string) => {
    const response = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>Publish Draft</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            <>
              Are you sure you want to publish this draft? This action will sync
              the product with Stripe, and make it available for purchase. It
              will <strong>not</strong> be marketed.
            </>
          </ConfirmCancelModalText>
        </div>
      ),
      confirmBtnLabel: 'Publish',
    });
    if (response.result !== 'confirmed') return;

    setLoading(true);
    try {
      await apiService.product.publishProduct(productId);
      revalidator.revalidate();
    } finally {
      setLoading(false);
    }
  };

  const handleCopyRegisterLink = async (product: DtoProduct) => {
    const prices = product.prices?.filter((p) => !p.archived) ?? [];
    if (prices.length > 0) {
      await triggerModal({
        kind: 'custom',
        containerClassName: 'bg-none',
        element: ({ internalOnCancel, internalOnConfirm }) => (
          <CopyRegisterLinkModal
            productId={product.id}
            prices={prices}
            onCancel={internalOnCancel}
            onCopy={(url) => {
              copy(url);
              internalOnConfirm();
            }}
          />
        ),
      });
    }
  };

  return (
    <div className='relative w-full text-white flex flex-col gap-10'>
      {loading && (
        <div className='fixed inset-0 flex items-center justify-center bg-lp-black-001 z-50'>
          <Loading text='' />
        </div>
      )}

      <header className='flex justify-between items-center pb-4'>
        <h1 className='text-3.5xl font-bold'>Products</h1>
      </header>

      <MarketedProductsEditor
        initialData={marketed}
        onChange={() => revalidator.revalidate()}
      />

      <ProductsTable
        title='Published'
        subtitle='Synced to Stripe and available for purchase, however not visible via the upgrade modal.'
        titleAccessory={
          <button
            type='button'
            className='btn flex justify-center items-center text-primary gap-1 text-sm hover:underline'
            onClick={handleEditProductDefaults}
          >
            Edit Defaults
          </button>
        }
        products={published}
        renderActionSheet={(product) => (
          <ProductActionSheetMenu>
            <ProductActionSheetMenuItem
              icon={<CopyIcon />}
              text='Copy Register Link'
              onClick={() => handleCopyRegisterLink(product)}
            />
            <ProductActionSheetMenuItem
              icon={<DuplicateIcon />}
              text='Duplicate As Draft'
              onClick={() => handleDuplicate(product.id)}
            />
            <ProductActionSheetMenuItem
              className='text-red-001'
              icon={<ArchiveIcon />}
              text='Archive'
              onClick={() => handleArchive(product.id)}
              disabled={marketed.find((p) => p.id === product.id) != null}
            />
          </ProductActionSheetMenu>
        )}
      />

      <ProductsTable
        title='Drafts'
        subtitle='Product drafts are not synced to Stripe and cannot be purchased.'
        titleAccessory={
          <button
            type='button'
            className='btn flex justify-center items-center text-primary gap-1 text-sm hover:underline'
            onClick={handleCreateProduct}
            disabled={creating}
          >
            <PlusIcon />
            {creating ? 'Creating...' : 'Create Product'}
          </button>
        }
        products={drafts}
        renderActionSheet={(product) => (
          <ProductActionSheetMenu>
            <ProductActionSheetMenuItem
              icon={<ShareIcon />}
              text='Publish'
              onClick={() => handlePublish(product.id)}
            />
            <ProductActionSheetMenuItem
              icon={<DuplicateIcon />}
              text='Duplicate'
              onClick={() => handleDuplicate(product.id)}
            />
            <ProductActionSheetMenuItem
              className='text-red-001'
              icon={<DeleteIcon />}
              text='Delete'
              onClick={() => handleDelete(product.id)}
            />
          </ProductActionSheetMenu>
        )}
      />

      <ProductsTable
        title='Archived'
        subtitle='Archived products are hidden from the public and cannot be purchased. Organizations may retain access to archived products.'
        products={archived}
        renderActionSheet={(product) => (
          <ProductActionSheetMenu>
            <ProductActionSheetMenuItem
              icon={<DuplicateIcon />}
              text='Duplicate as Draft'
              onClick={() => handleDuplicate(product.id)}
            />
          </ProductActionSheetMenu>
        )}
      />
    </div>
  );
}

function ProductPicker(props: {
  selected: string | null | undefined;
  onSelect: (product: DtoProduct) => void;
}): JSX.Element | null {
  const { selected, onSelect } = props;

  // note(falcon): not using an async select component because there's no search really.
  const { data, isLoading, error } = usePublishedProducts();
  const value = useMemo(() => {
    if (!selected || !data) return null;
    return data.find((p) => p.id === selected) ?? null;
  }, [selected, data]);
  const styles = useInstance(() => buildReactSelectStyles<DtoProduct>());

  const handleChange = (option: SingleValue<DtoProduct>) => {
    if (!option) return;
    onSelect(option);
  };

  if (isLoading) {
    return (
      <div className='w-full flex items-center justify-center'>
        <Loading text='' />
      </div>
    );
  }

  if (error) {
    return (
      <div className='w-full flex items-center justify-center'>
        <p className='text-red-001'>{err2s(error)}</p>
      </div>
    );
  }

  return (
    <Select<DtoProduct, false>
      placeholder='Select product...'
      styles={styles}
      classNamePrefix='select-box-v2'
      className='w-full'
      value={value}
      options={data}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      onChange={handleChange}
      isSearchable
    />
  );
}

function EditProductSettings(props: {
  onCancel: () => void;
  onComplete: () => void;
}) {
  const { data: productDefaults, isLoading, error } = useProductDefaults();

  if (isLoading) {
    return (
      <div className='w-full flex items-center justify-center'>
        <Loading text='' />
      </div>
    );
  }

  if (error || !productDefaults) {
    return (
      <div className='w-full flex items-center justify-center'>
        <p className='text-red-001'>{err2s(error)}</p>
      </div>
    );
  }

  return <EditProductSettingsForm initial={productDefaults} {...props} />;
}

function EditProductSettingsForm(props: {
  initial: DtoProductDefaultsResponse;
  onCancel: () => void;
  onComplete: () => void;
}) {
  const [data, setData] = useState(props.initial);
  const { trigger: updateProductDefaults, isMutating } =
    useUpdateProductDefaults();

  const handleSave = useLiveCallback(async () => {
    await updateProductDefaults(data);
    props.onComplete();
  });

  return (
    <div className='border border-secondary bg-black rounded-xl px-5 py-3 w-142 min-h-45'>
      <div className='w-full h-full flex flex-col text-white'>
        <div className='flex-none w-full py-2'>
          <div className='font-bold text-2xl'>Product Settings</div>
          <div className='text-base text-icon-gray'>
            Edit app-wide product defaults
          </div>
        </div>

        <div className='flex-grow flex-shrink-0 w-full py-6 space-y-4'>
          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>Default Plan</div>
              <div className='text-xs text-icon-gray'>
                Defines the default plan for all newly created orgs.
              </div>
            </div>
            <ProductPicker
              selected={data.defaultProductId}
              onSelect={(product) =>
                setData((prev) => ({ ...prev, defaultProductId: product?.id }))
              }
            />
          </div>
          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>Most Popular Plan</div>
              <div className='text-xs text-icon-gray'>
                Defines the plan to be used for upsell/most-popular scenarios,
                e.g., one-off purchase upsells.
              </div>
            </div>
            <ProductPicker
              selected={data.mostPopularProductId}
              onSelect={(product) =>
                setData((prev) => ({
                  ...prev,
                  mostPopularProductId: product?.id,
                }))
              }
            />
          </div>
          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>Demo Plan</div>
              <div className='text-xs text-icon-gray'>
                Defines the plan to be used for orgs created through the demo
                ("Try it Now") flow.
              </div>
            </div>
            <ProductPicker
              selected={data.demoProductId}
              onSelect={(product) =>
                setData((prev) => ({ ...prev, demoProductId: product?.id }))
              }
            />
          </div>
          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>Live Plan</div>
              <div className='text-xs text-icon-gray'>
                Defines the plan to be used for live scenarios, e.g., one-off
                live event purchase.
              </div>
            </div>
            <ProductPicker
              selected={data.liveProductId}
              onSelect={(product) =>
                setData((prev) => ({ ...prev, liveProductId: product?.id }))
              }
            />
          </div>
          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>Small Head Count Paid Plan</div>
              <div className='text-xs text-icon-gray'>
                Defines the best plan for head counts of 10 or fewer.
              </div>
            </div>
            <ProductPicker
              selected={data.smallHeadCountPaidProductId}
              onSelect={(product) =>
                setData((prev) => ({
                  ...prev,
                  smallHeadCountPaidProductId: product?.id,
                }))
              }
            />
          </div>
          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>Default Training Plan</div>
              <div className='text-xs text-icon-gray'>
                Defines the plan to be used for orgs created through the
                training flow.
              </div>
            </div>
            <ProductPicker
              selected={data.defaultTrainingProductId}
              onSelect={(product) =>
                setData((prev) => ({
                  ...prev,
                  defaultTrainingProductId: product?.id,
                }))
              }
            />
          </div>
        </div>

        <div className='mt-auto w-full pb-2 flex items-center justify-end gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={props.onCancel}
            disabled={isMutating}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 py-2'
            onClick={handleSave}
            disabled={isMutating}
          >
            {isMutating ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
}

export function Component() {
  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />

      <AdminProductsLibrary />
    </AdminView>
  );
}
